<template>
    <Produtor />
</template>

<script>
    import Produtor from '../../components/consulta/Produtor.vue';

    export default {
        components: { Produtor },
        setup() {
            
        },
    }
</script>